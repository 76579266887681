/* Global Styles */
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: var(--background-color);
  color: var(--primary-color);
}

/* Contact Container */
.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: var(--background-color);
}

/* Social Section */
.social {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.social > div {
  margin: 10px 0;
  padding: 5px;
}

@media (min-width: 768px) {
  .contact {
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 20px;
  }

  .social, .contact-form {
    width: 50%;
    padding: 0 20px;
  }
}

/* Contact Form */
.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--primary-color);
  padding:1%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
}

.contact-form h2 {
  color: var(--secondary-color);
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.contact-form label {
  display: block;
  color: var(--secondary-color);
  font-size: 16px;
  margin-bottom: 8px;
}

.contact-form input, 
.contact-form textarea {
  width:93% ;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  background-color: #fff;
  color: var(--primary-color);
}
.contact-form select {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  background-color: #fff;
  color: var(--primary-color);
}
.contact-form input:focus, 
.contact-form select:focus, 
.contact-form textarea:focus {
  border-color: var(--secondary-color);
  outline: none;
}

.contact-form textarea {
  resize: vertical;
  min-height: 120px;
}

.contact-form button {
  padding: 12px;
  background-color: var(--secondary-color);
  color: var(--text-color);
  border: none;
  border-radius: 6px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-form button:hover {
  background-color: var(--secondary-color-hover);
}

.contact-form span {
  display: block;
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

/* Responsive Styles */
@media (max-width: 767px) {
  .contact-form {
    width: 100%;
    padding: 0;
  }
  .contact{
    padding: 0;
  }
  .social{
    padding: 20px;
  }
  .contact-form {
    margin-bottom: 20px;
  }
}
