  
  .services-container {
    padding: 60px 0;
    background-color: var(--background-color);
    text-align: center;
  }
  
  .section-title {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 2rem;
  }
  
  .services-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: center;
    padding: 15px;
  }
  
  .service-card {
    background-color: var(--text-color);
    color: var(--background-color);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    max-width: 250px;
    transition: transform 0.3s ease-in-out;
  }
  
  .service-card:hover {
    transform: scale(1.05);
  }
  
  .service-icon {
    font-size: 3rem;
    margin-bottom: 1rem;
    color: var(--secondary-color);
  }
  
  .service-title {
    font-size: 1.5rem;
    color: var(--secondary-color-hover);
    margin-bottom: 0.5rem;
  }
  
  .service-description {
    font-size: 1rem;
    color: var(--background-color);
  }
  
  /* Responsive Styles */
  @media (max-width: 600px) {
    .service-card {
      max-width: 100%;
      padding: 1rem;
    }
  
    .section-title {
      font-size: 1.5rem;
    }
  }
  
  @media (min-width: 600px) and (max-width: 960px) {
    .service-card {
      max-width: 100%;
    }
  
    .section-title {
      font-size: 1.75rem;
    }
  }
  