

  /* General Navbar Styles */
  .navbar {
    background-color: var(--primary-color);
    color: var(--background-color);
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: static;
  }
  
  .navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  .navbar-logo {
    width: 100px;
    height: auto;
    max-width: 30vw;
  }
  
  .navbar-title {
    margin: 10px;
    font-size: 1.25rem;
  }
  
  .navbar-links {
    display: flex;
    gap: 20px;
    margin-left: auto;
  }
  
  .nav-link {
    color: var(--secondary-color);
    text-decoration: none;
  }
  
  .nav-link:hover {
    color: var(--background-color);
  }
  
  /* Call to Action Button */
  .cta-button {
    background-color: var(--secondary-color);
    color: var(--text-color);
    padding: 10px 15px;
    text-decoration: none;
    margin-left: 20px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: var(--secondary-color-hover);
  }
  
  /* Mobile Menu Icon */
  .menu-icon {
    background: none;
    border: none;
    color: var(--secondary-color-hover);
    font-size: 1.5rem;
    cursor: pointer;
    display: none;
  }
  
  /* Drawer Styles */
  .drawer {
    position: fixed;
    top: 0;
    width: 290px;
    z-index: 999;
    height: 100%;
    background-color: var(--primary-color);
    color: var(--text-color);
    transition: right 0.3s ease;
    padding: 20px;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  }
  
  .drawer.open {
    right: 0;
  }
  
  .close-drawer {
    background: none;
    border: none;
    color: var(--secondary-color);
    font-size: 1.5rem;
    position: absolute;
    top: 20px;
    left: 20px;
    cursor: pointer;
  }
  
  .drawer-links {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 50px;
  }
  
  .drawer-link {
    color: var(--background-color);
    text-decoration: none;
  }
  
  .drawer-link:hover {
    text-decoration: underline;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .navbar-links {
      display: none;
    }
  
    .menu-icon {
      display: block;
    }
  }
  
  @media (max-width:425px) {
    .navbar-title,.cta-button{
        display: none;
    }
  }