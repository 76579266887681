.floating-buttons {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.floating-button {
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50%;
  font-size: 20px;
  color: white;
  background-color: #007bff; /* Customize color */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.floating-button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

.top-button {
  background-color: #28a745; /* Green color */
  border-radius:12px; 
}

.whatsapp-button {
  background-color: #25d366; /* WhatsApp color */
  text-decoration: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.whatsapp-button:hover {
  background-color: #128c7e; /* Darker shade on hover */
}
