

.portfolio-container {
  padding: 60px 0;
  background-color: var(--background-color);
  text-align: center;
}

.section-title {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 2rem;
}

.portfolio-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: center;
  padding: 15px;
}

.portfolio-item {
  background-color: var(--text-color);
  color: var(--background-color);
  border: 1px solid #ddd;
  border-radius: 15px;
  overflow: hidden;
  max-width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.portfolio-item:hover {
  transform: scale(1.05);
}

.portfolio-image {
  width: 100%;
  height: auto;
}

.portfolio-content {
  padding: 10px;
}

.portfolio-title {
  font-size: 1.25rem;
  color: var(--secondary-color);
  margin-bottom: 0.5rem;
}

.portfolio-description {
  font-size: 1rem;
}

/* Responsive Styles */
@media (max-width: 600px) {
  .portfolio-item {
    max-width: 100%;
    padding: 1rem;
  }

  .section-title {
    font-size: 1.5rem;
  }
}

@media (min-width: 600px) and (max-width: 960px) {
  .portfolio-item {
    max-width: 100%;
  }

  .section-title {
    font-size: 1.75rem;
  }
}
