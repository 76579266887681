/* Declare colors and other constants in :root */
:root {
  --carousel-bg-color: rgba(0, 0, 0, 0.5);
  --carousel-indicator-color: rgba(255, 255, 255, 0.5);
  --carousel-indicator-active-color: white;
  --carousel-button-color: white;
  --carousel-slide-bg-color: rgba(0, 0, 0, 0.5);
  --carousel-transition-duration: 0.5s;
  --carousel-slide-padding: 20px;
  --carousel-slide-border-radius: 10px;
  --carousel-btn-padding: 10px 20px;
  --carousel-btn-border-radius: 5px;
}

/* Carousel Container */
.carousel {
  position: relative;
  width: 100%;
  max-width: var(--carousel-max-width);
}

/* Carousel Content */
.carousel__content {
  display: flex;
  transition: transform var(--carousel-transition-duration) ease-in-out;
  transform: translateX(calc(-100% * var(--currentIndex)));
  position: relative;
}

/* Carousel Slide */
.carousel__slide {
  min-width: 100%;
  transition: opacity var(--carousel-transition-duration) ease-in-out;
  opacity: 0;
  height: var(--carousel-slide-height);
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide--active {
  opacity: 1;
}

/* Slide Content */
.carousel__slide-content {
  text-align: center;
  color: white;
  padding: var(--carousel-slide-padding);
  background: var(--carousel-slide-bg-color);
  border-radius: var(--carousel-slide-border-radius);
}

/* Buttons inside the slide */
.carousel__btn {
  background-color: var(--carousel-button-color);
  color: black;
  border: none;
  padding: var(--carousel-btn-padding);
  margin: 10px;
  cursor: pointer;
  border-radius: var(--carousel-btn-border-radius);
}

/* Carousel Buttons */
.carousel__button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--carousel-bg-color);
  color: var(--carousel-button-color);
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.carousel__button--left {
  left: 10px;
}

.carousel__button--right {
  right: 10px;
}

/* Carousel Indicators */
.carousel__indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
}

.carousel__indicator {
  width: 10px;
  height: 10px;
  background-color: var(--carousel-indicator-color);
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.carousel__indicator--active {
  background-color: var(--carousel-indicator-active-color);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  :root {
    --carousel-slide-height: 300px;
    --carousel-slide-padding: 15px;
    --carousel-btn-padding: 8px 15px;
  }

}


@media (max-width: 480px) {
  :root {
    --carousel-slide-height: 250px;
    --carousel-slide-padding: 10px;
    --carousel-btn-padding: 6px 12px;
  }
}
