  
  .testimonial-container {
    padding: 60px 0;
    background-color: var(--background-color);
    text-align: center;
  }
  
  .section-title {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 2rem;
  }
  
  .testimonial-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: center;
    padding: 15px;
  }
  
  .testimonial-card {
    background-color: var(--text-color);
    color: var(--background-color);
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 2rem;
    max-width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .testimonial-card:hover {
    transform: scale(1.05);
  }
  
  .testimonial-header {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .testimonial-image {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin-right: 1rem;
  }
  
  .testimonial-info {
    text-align: left;
  }
  
  .testimonial-name {
    font-size: 1.25rem;
    color: var(--secondary-color-hover);
    margin-bottom: 0.5rem;
  }
  
  .testimonial-location, .testimonial-profession {
    font-size: 0.875rem;
    color: var(--secondary-color);
    margin-bottom: 0.25rem;
  }
  
  .testimonial-text {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .video-thumbnail {
    position: relative;
    width: 100%;
    height: auto;
  }
  
  .thumbnail-image {
    width: 100%;
    border-radius: 8px;
  }
  
  .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 10px;
  }
  
  @media (max-width: 600px) {
    .testimonial-card {
      max-width: 100%;
      padding: 1rem;
    }
  
    .section-title {
      font-size: 1.5rem;
    }
  }
  
  @media (min-width: 600px) and (max-width: 960px) {
    .testimonial-card {
      max-width: 100%;
    }
  
    .section-title {
      font-size: 1.75rem;
    }
  }
  