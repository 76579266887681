.blog-container {
  padding: 60px 20px;
  background-color: var(--background-color);
  color: var(--text-color);
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    "title"
    "image"
    "content";
  gap: 1.5rem;
}

/* Blog Title */
.blog-title {
  font-size: clamp(2rem, 5vw, 3rem); /* Responsive font size */
  color: var(--primary-color);
  text-align: center;
  padding: 10px;
}
.blog-subtitle{
  font-size: clamp(1.25rem, 4vw, 1.75rem); /* Responsive font size */
  color: var(--secondary-color);
  margin-bottom: 1rem;
  text-align: center;
  font-weight: 400; /* Regular weight for balance */
  line-height: 1.4;
  grid-area: title;
}
.blog-breadcrumb {
  color: var(--secondary-color);
  display: flex;
  align-items: center; /* Align items vertically in the center */
  gap: 0.1rem; /* Space between breadcrumb items */
  font-size: 1rem; /* Adjust font size as needed */
  margin-left: 20px;
  font-weight: 900;
}


.blog-breadcrumb a:hover {
  color: var(--secondary-color); /* Change color on hover */
}

.blog-breadcrumb .current-page {
  font-weight: bold; /* Make current page text bold */
  color: var(--text-color); /* Use text color for current page */
}

.blog-breadcrumb span {
  display: flex;
  align-items: center; /* Center items vertically */
}


.blog-intro,
.blog-benefits,
.blog-usecase,
.blog-conclusion {
  text-align: justify;
  text-align-last: left;
  padding: 20px; /* Keeps the last line left-aligned */
}

/* Blog Subtitle */
.blog-subtitle {
  font-size: clamp(1.25rem, 4vw, 1.75rem); /* Responsive font size */
  color: var(--secondary-color);
  margin-bottom: 1rem;
  text-align: center;
  font-weight: 400; /* Regular weight for balance */
  line-height: 1.4;
}

/* Blog Content */
.blog-content {
  font-size: 1.125em; /* Slightly larger than base font size */
  line-height: 1.75;
  color: var(--text-color);
  margin-bottom: 2rem;
  font-weight: 300; /* Light weight for a clean look */
  grid-area: content;
}

/* Blog Image */
/* Blog Image Container */
.blog-image-container {
  margin: 2rem 0; /* Space around the image */
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Blog Image */
.blog-image {
  max-width: 100%; /* Ensures image doesn't overflow its container */
  height: auto; /* Maintains aspect ratio */
  border-radius: 12px; /* Smooth corners */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  object-fit: cover; /* Ensures the image covers the area without distortion */
}

/* Responsive Adjustments */
@media (max-width: 600px) {
  .blog-image-container {
    margin: 1.5rem 0;
  }
}

/* Blockquote */
.blog-quote {
  font-size: 1.25em; /* Slightly larger than base font size */
  font-style: italic;
  color: var(--secondary-color-hover);
  padding: 1rem 1.5rem;
  border-left: 4px solid var(--primary-color); /* Thinner border for a subtle look */
  margin: 2rem 0;
  background-color: var(
    --background-alt-color
  ); /* Use an alternate background for contrast */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Blog List */
.blog-list {
  margin: 2rem 0;
  padding-left: 1.5rem;
}

.blog-list li {
  font-size: 1.125em;
  color: var(--text-color);
  margin-bottom: 0.75rem; /* Increase spacing between list items */
}

/* Blog Links */
.blog-content a {
  color: var(--primary-color);
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  font-weight: 500; /* Medium weight for emphasis */
}

.blog-content a:hover {
  color: var(--secondary-color-hover);
  text-decoration: underline;
}

/* Blog Footer */
.blog-footer {
  margin-top: 4rem;
  padding-top: 2rem;
  border-top: 1px solid var(--secondary-color);
  text-align: center;
  color: var(--text-color);
  font-size: 0.875em; /* Slightly smaller footer text */
}

/* Responsive Styles */
@media (max-width: 600px) {
  
  .blog-title {
    font-size: 1.75rem; /* Slightly smaller for very small screens */
  }

  .blog-subtitle {
    font-size: 1.25rem;
  }

  .blog-content {
    font-size: 1rem;
  }

  .blog-quote {
    font-size: 1.15rem;
  }
}

@media (min-width: 600px) and (max-width: 960px) {
  .blog-title {
    font-size: 2rem;
  }

  .blog-subtitle {
    font-size: 1.4rem;
  }

  .blog-content {
    font-size: 1.125rem;
  }

  .blog-quote {
    font-size: 1.2rem;
  }
}

@media (min-width: 768px) {
  .blog-container {
    grid-template-columns: 1fr 2fr; /* Two-column layout on medium screens and up */
    grid-template-areas:
      "title title"
      "image content";
  }

  .blog-image {
    margin-bottom: 0; /* Remove bottom margin when in the same row as content */
    max-height: 300px; /* Adjust image height for medium screens */
  }
}

@media (min-width: 1024px) {
  .blog-container {
    grid-template-columns: 2fr 3fr; /* Wider content area on larger screens */
    grid-template-areas:
      "title title"
      "content image";
  }

  .blog-image {
    margin-left: 2rem; /* Add margin to separate content and image */
    max-height: 350px; /* Adjust image height for larger screens */
  }
}
