html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}


:root {
  --primary-color: #2c3e50; /* Rich dark blue for primary color */
  --secondary-color: #1abc9c; /* Soft teal for accents */
  --secondary-color-hover: #16a085; /* Darker teal for hover effect */
  --text-color: #2c3e50; /* Dark blue for text for consistency */
  --background-color: #ecf0f1; /* Light grayish blue background for a clean look */
}

a{
  text-decoration: none;
}
img{
  border-radius: 15px;
}

.homebanner {
  background: url(../public/banner.jpg) no-repeat center center/cover;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 120px;
  position: relative;
  width: 100%;
  background-position: center;
}
@media (min-width: 768px) and (max-width: 991px) {
  .homebanner {
      padding-top: 150px;
      height: 600px;
  }
}